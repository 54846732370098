import { createTheme } from '@material-ui/core';
import Colors from './Colors';

export const primary = {
  shape: {
    borderRadius: 7
  },
  palette: {
    primary: Colors.teal,
    secondary: Colors.grey,
    text: Colors.text,
    error: {
      main: '#CF0000',
    },
    background: {
      main: '#f9fafc'
    },
    appBar: {
      background: '#ffffff',
    },
    warn: {
      main: '#ED9D66',
    },
  },
  dimens: {
    radius: 7,
    spacing: 8,
    text: {
      normal: 16,
      caption: 12,
      title: 40,
    },
  },
  overrides: {
    MuiTimelineItem: {
      alignAlternate: {
        '&:nth-child(even) .add-entry': {
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 24,
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        },
      },
      '&:nth-child(even) .edit-entry': {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      },
    }
  }
};

export default createTheme(primary);