import React from 'react';
import PropTypes from 'prop-types';
import { Backdrop, CircularProgress } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';

const Loading = (props) => {
  const classes = useStyles(props);
  return (
    <Backdrop
      className={classes.root}
      open={props.loading}
      data-testid={props['data-testid']}>
      <CircularProgress variant={'indeterminate'} color={'inherit'} />
      {
        !!props.loadingText && (
          <Typography variant={'caption'}>{ props.loadingText }</Typography>
        )
      }
    </Backdrop>
  );
};

Loading.propTypes = {
  loading: PropTypes.bool,
  color: PropTypes.string,
};

const useStyles = makeStyles(
  theme => ({
    root: props => ({
      zIndex: theme.zIndex.drawer + 1,
      color: props.color || 'white',
    }),
    text: {
      marginTop: theme.spacing(2),
    }
  })
)

export default Loading;
