import GlobalEvents from '../singletons/GlobalEvents';
import parseBackendErrors from './parseBackendErrors';

const reportError = (type, err, message, filename, lineno, colno) => {
  if (err) {
    console.log(`Error type: ${type}`);
    console.error(err);
  }
  const printedError = parseBackendErrors(err, message);

  if (printedError) {
    GlobalEvents.emit('unknownError', {
      printedError,
    });
  }
};

export default reportError;
