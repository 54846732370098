export function shadeColor(color, percent) {

  let R = parseInt(color.substring(1,3),16);
  let G = parseInt(color.substring(3,5),16);
  let B = parseInt(color.substring(5,7),16);

  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;

  var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

const Colors = {
  pink: {
    main: '#f48fb1',
    dark: '#e91e63',
  },
  teal: {
    main: '#29a69a',
    dark: '#00796b'
  },
  green: {
    main: '#81c784',
    dark: '#4caf50',
  },
  grey: {
    main: '#e0e0e0',
    dark: '#9e9e9e',
  },
  text: {
    primary: '#1c1d1f',
    caption: 'rgb(113,113,115)',
    secondary: '#c7c9cC',
    link: '#36c9eb',
    disabled: '#c7c9cC'
  },
};

export default Colors;