import './App.css';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import primary from './themes/primary';
import { pink } from '@material-ui/core/colors';
import { shadeColor } from './themes/Colors';
import { lazy, useCallback, useEffect, useMemo, useState, Suspense } from 'react';
import Codes, { isCodeAuthentic } from './codes';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import {BrowserRouter as Router} from 'react-router-dom';
import ErrorCatcher from './components/ErrorCatcher';
import { ToastContainer, Slide } from 'material-react-toastify';
import Loading from './components/Loading';

const RouterTree = lazy(() => import('./RouterTree'));

function App() {
  const classes = useStyles();

  const [ isAuthenticated, setIsAuthenticated ] = useState(false);
  const [ isSurpriseTime, setIsSurpriseTime ] = useState(false);
  const [ accessCode, setAccessCode ] = useState('');

  const handleAccessSubmit = useCallback((code) => {
    console.log({ code });
    setIsAuthenticated(isCodeAuthentic(code));
    setAccessCode(code);
  }, []);

  const invalidCode = useMemo(() => !!accessCode && !isCodeAuthentic(accessCode), [accessCode]);

  useEffect(() => {
    if (accessCode?.toLowerCase() === Codes.elizabeth) {
      const timer = setTimeout(() => {
        setIsSurpriseTime(true);
      }, 15 * 1000);
      return () => clearTimeout(timer);
    }
  }, [accessCode]);

  return (
    <ThemeProvider theme={primary}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Router>
          <ErrorCatcher>
            <Suspense fallback={<Loading />}>
              <RouterTree />
            </Suspense>
            <ToastContainer position={'top-right'} transition={Slide} />
          </ErrorCatcher>
        </Router>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

const useStyles = makeStyles({
  root: {
    backgroundColor: shadeColor(pink[50], 10),
    minHeight: '100vh',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
  },
  container: {
    position: 'relative',
    maxWidth: 1280,
    width: '100%',
    margin: 'auto'
  }
})

export default App;
